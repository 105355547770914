import axios from 'axios'
import router from './router'

// Request interceptor
/*axios.interceptors.request.use(request => {
  console.log('Starting Request', request);
  return request;
}, error => {
  console.error('Request Error:', error);
  return Promise.reject(error);
});*/

// Response interceptor
/*axios.interceptors.response.use(response => {
  console.log('Response:', response);
  return response;
}, error => {
  console.error('Response Error:', error);
  return Promise.reject(error);
});*/

// set apiUrl
const apiUrl = (process.env.NODE_ENV === 'production')
  ? 'https://apiv2.gaf.adro.studio'
  : 'http://192.168.1.100'

export default {
  async getConfig() {
    const configurl = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/config.json"

    const response = await axios.get(
      //window.location.protocol+"//" +window.location.hostname.replace("www.", "") +":" +window.location.port +"/config.json"
      configurl
    )
      .catch((error: any) => {
        if (error.response.status == 500) {
          router.push('/disconnected')
        }
        return error.response
      }) as any

    return response.data
  },

  async getFromApi(request: string) {
    const response = await axios.get(apiUrl + request)
      .catch(error => {
        if (error.response.status == 500) {
          router.push('/disconnected')
        }
        return error.response
      })

    return response
  },

  async postToApi(request: string, data: Record<string, unknown>) {
    const response = await axios.post(apiUrl + request, data)
      .catch(error => {
        if (error.response.status == 500) {
          router.push('/disconnected')
        }
        return error.response
      })

    return response
  },

  getFormattedTicket(ticketData: any[any]) {
    /*const formattedTicket = `
                <body style="font-family:Courier New;display:flex;margin-top:0px; margin-left:24px">
                  <div style="width:188px;flex-shrink:0">
                    <p style="font-size:11px;font-weight:bold;">${ticketData.cine}</p>
                    <p style="font-size:11px;font-weight:bold;">Sala ${ticketData.sala}</p>
                    <p style="font-size:11px;">${ticketData.fechaVenta} - ${ticketData.horaVenta}</p>
                    <p style="font-size:20px;font-weight:bold;height:20px;overflow:hidden;">${ticketData.pelicula}</p>
                    <p style="font-size:18px;font-weight:bold;">Fecha: ${ticketData.fechaFuncion}</p>
                    <p style="font-size:18px;font-weight:bold;">Hora: ${ticketData.horaFuncion}</p>
                    <p style="font-size:18px;font-weight:bold;">Precio: $${ticketData.precio}</p>
                    <p style="font-size:18px;font-weight:bold;">POS: ${(ticketData.ubicacion === undefined) ? '-' : ticketData.ubicacion}</p>
                    <p style="font-size:11px;">${ticketData.nroEntrada}</p>
                    <p style="font-size:11px;">Sala: ${ticketData.codigoSala}</p>
                    <p style="font-size:11px;">Función: ${ticketData.funcion}</p>
                    <p style="font-size:11px;">CUIT: ${ticketData.cuit}</p>
                    <p style="font-size:11px;">Talón para el espectador</p>
                  </div>
                  <div style="width:113px;flex-shrink:0">
                    <p style="font-size:11px;">${ticketData.cine}</p>
                    <p style="font-size:11px;">Sala ${ticketData.sala}</p>
                    <p style="font-size:11px;margin-bottom:26px">${ticketData.fechaVenta} - ${ticketData.horaVenta}</p>
                    <p style="font-size:11px;margin-bottom:28px">${ticketData.pelicula}</p>
                    <p style="font-size:11px;margin-bottom:26px">Fecha: ${ticketData.fechaFuncion}</p>
                    <p style="font-size:11px;margin-bottom:26px">Hora: ${ticketData.horaFuncion}</p>
                    <p style="font-size:11px;margin-bottom:26px">Precio: $${ticketData.precio}</p>
                    <p style="font-size:11px;margin-bottom:26px">POS: ${(ticketData.ubicacion === undefined) ? '-' : ticketData.ubicacion}</p>
                    <p style="font-size:11px">${ticketData.nroEntrada}</p>
                    <p style="font-size:11px;">Sala: ${ticketData.codigoSala}</p>
                    <p style="font-size:11px;">Función: ${ticketData.funcion}</p>
                    <p style="font-size:11px;">CUIT: ${ticketData.cuit}</p>
                    <p style="font-size:11px;">Talón para urna</p>
                  </div>
                </body>`*/

    const formattedTicket = `
                            <style>
                            * {
                              margin:0;
                              padding:0;
                              box-sizing:border-box;
                            }
                            </style>
                            <body style="font-family:Arial, sans-serif;margin-left:24px;display:flex;flex-direction:column;gap:8px;">
                              <div class="row" style="display:flex;align-items:flex-end;margin-bottom:16px;">
                                <p style="width:188px;flex-shrink:0;font-size:11px;font-weight:bold;text-transform:uppercase;">${ticketData.fechaVenta} - ${ticketData.horaVenta}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;text-transform:uppercase;">${ticketData.fechaVenta} - ${ticketData.horaVenta}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;">
                                <p style="width:188px;flex-shrink:0;font-size:11px;">${ticketData.cine}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;">${ticketData.cine}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;">
                                <p style="width:188px;flex-shrink:0;font-size:18px;font-weight:bold;">Sala ${ticketData.sala}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;">Sala ${ticketData.sala}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;height:18px;overflow:hidden;">
                                <p style="width:188px;flex-shrink:0;font-size:18px;font-weight:bold;text-transform:uppercase;">${ticketData.pelicula}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;text-transform:uppercase;">${ticketData.pelicula}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;">
                                <p style="width:188px;flex-shrink:0;font-size:14px;font-weight:bold;">Fec. ${ticketData.fechaFuncion}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;">Fec. ${ticketData.fechaFuncion}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;">
                                <p style="width:188px;flex-shrink:0;font-size:14px;font-weight:bold;">Hor. ${ticketData.horaFuncion}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;">Hor. ${ticketData.horaFuncion}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;margin-bottom:16px;">
                                <p style="width:188px;flex-shrink:0;font-size:14px;font-weight:bold;">Pos. ${(ticketData.ubicacion === undefined) ? '-' : ticketData.ubicacion}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;">Pos: ${(ticketData.ubicacion === undefined) ? '-' : ticketData.ubicacion}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;margin-bottom:16px;">
                                <p style="width:188px;flex-shrink:0;font-size:11px;">${ticketData.nroEntrada}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;">${ticketData.nroEntrada}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;margin-bottom:16px;">
                                <p style="width:188px;flex-shrink:0;font-size:14px;font-weight:bold;">Precio $${ticketData.precio}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;">Precio $${ticketData.precio}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;">
                                <p style="width:188px;flex-shrink:0;font-size:11px;">Sala: ${ticketData.codigoSala}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;">Sala: ${ticketData.codigoSala}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;">
                                <p style="width:188px;flex-shrink:0;font-size:11px;;">Función: ${ticketData.funcion}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;">Función: ${ticketData.funcion}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;">
                                <p style="width:188px;flex-shrink:0;font-size:11px;">CUIT: ${ticketData.cuit}</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;">CUIT: ${ticketData.cuit}</p>
                              </div>
                              <div class="row" style="display:flex;align-items:flex-end;">
                                <p style="width:188px;flex-shrink:0;font-size:11px;">Talón para el espectador</p>
                                <p style="width:113px;flex-shrink:0;font-size:11px;">Talón para urna</p>
                              </div>
                            </body>`

    return formattedTicket
  },

  async getComingSoon(category: string, request: string) {

    const response = await axios.get('https://admin.enelcine.com.ar/api/items/' + request).catch(error => {
      //router.push('/disconnected')
      throw 'Error de Servidor'
    })

    const items = response.data.data

    Promise.resolve(response).then(() => {
      // news
      if (category == "noticias") {
        items.forEach((item: { [key: string]: any }, index: number) => {
          if (item.portada !== null && item.titulo !== null && item.creado !== null) {
            items[index].creado = this.getElapsedTime(item.creado)
          }
        })
      }

      // movies
      if (category == "peliculas") {
        items.forEach((item: { [key: string]: any }, index: number) => {

          items[index].titulo = (item.titulo_local) ? item.titulo_local : item.titulo_original

          if (item.fecha_local == this.getThu()) {
            items[index].estreno = true
          }
        })
      }
    })

    return items
  },

  getThu: function () {
    // set dates
    const today = new Date()
    let thisThu = new Date()
    const thuNmb = 4
    let todayNmb = today.getDay()

    // set number for sunday
    if (todayNmb == 0) {
      todayNmb = 7
    }

    // if not default day number
    if (todayNmb != 4) {
      const diff = (todayNmb > thuNmb) ? todayNmb - thuNmb : thuNmb + (todayNmb - 1)
      thisThu.setDate(today.getDate() - diff)
    } else {
      thisThu = today
    }

    thisThu = this.getStringDate(thisThu) as any

    return thisThu
  },

  getStringDate: function (date: Date) {
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yyyy = date.getFullYear()
    const formattedDate = yyyy + '-' + mm + '-' + dd as any

    return formattedDate
  },

  getElapsedTime: function (date: string) {
    date = date.replace(' ', 'T') + 'Z'
    const prevTime = new Date(date)
    const thisTime = new Date()
    let timeDiff = thisTime.getTime() - prevTime.getTime()
    timeDiff /= 1000;
    timeDiff = Math.round(timeDiff)

    let time

    if (timeDiff > 31536000) {
      timeDiff = Math.round(timeDiff / 31536000)
      time = (timeDiff <= 1) ? 'año' : 'años'
    } else if (timeDiff > 2629750) {
      timeDiff = Math.round(timeDiff / 2629750)
      time = (timeDiff <= 1) ? 'mes' : 'meses'
    } else if (timeDiff > 604800) {
      timeDiff = Math.round(timeDiff / 604800)
      time = (timeDiff <= 1) ? 'semana' : 'semanas'
    } else if (timeDiff > 86400) {
      timeDiff = Math.round(timeDiff / 86400)
      time = (timeDiff <= 1) ? 'día' : 'días'
    } else if (timeDiff > 3600) {
      timeDiff = Math.round(timeDiff / 3600)
      time = (timeDiff <= 1) ? 'hora' : 'horas'
    } else if (timeDiff > 60) {
      timeDiff = Math.round(timeDiff / 60)
      time = (timeDiff <= 1) ? 'minuto' : 'minutos'
    }

    return 'Hace ' + timeDiff + ' ' + time
  },

  getFormatedDate: function (date: string) {
    let formatedDate
    formatedDate = new Date(date + 'T12:00:00.000Z')
    formatedDate = new Intl.DateTimeFormat('es-ES', { dateStyle: 'medium', timeZone: 'America/Argentina/Buenos_Aires' } as any).format(formatedDate)
    return formatedDate
  },

  setHeader: function (title: string) {

    const url = window.location
    const description = "¡Comprá tu entrada y vení a disfrutar el mejor cine!"

    document.head.innerHTML += '<title>' + title + '</title>'

    // Primary Tags
    /*documentElement.querySelector('head title').textContent = title
    documentElement.querySelector('[name="description"]').setAttribute('content', description)

    // Facebook
    //documentElement.querySelector('[property="og:image"]').setAttribute('content', image)
    documentElement.querySelector('[property="og:title"]').setAttribute('content', title)
    documentElement.querySelector('[property="og:url"]').setAttribute('content', url)
    documentElement.querySelector('[property="og:description"]').setAttribute('content', description)

    // Twitter
    //documentElement.querySelector('[property="twitter:image"]').setAttribute('content', image)
    documentElement.querySelector('[property="twitter:title"]').setAttribute('content', title)
    documentElement.querySelector('[property="twitter:url"]').setAttribute('content', url)
    documentElement.querySelector('[property="twitter:description"]').setAttribute('content', description)*/
  }
}